@import '../../../styles/var';

.footer {
    padding: 35px 0 45px;
}

.content {
    color: rgba(0, 0, 0, 0.70);
    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.70);
        &:hover {
            color: #000!important;
        }
    }

    .head {

        .title {

        }

        .text {
        }
    }

    .contacts {

        .item {
            display: flex;
            align-items: center;
            gap: 20px;
            color: rgba(0, 0, 0, 0.70);
            svg {
                width: 24px;
            }
        }
    }

    .docs {

    }

    .contacts, .docs {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
}