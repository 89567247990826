@import "../../../styles/var";

.why {}

.whyBody {}

.whyTitle {
    text-align: center;
    margin-bottom: 60px;

    @media (max-width: $md5+px) {
        margin-bottom: 40px;
    }
}

.whyRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.whyRowColumn {
    flex: 0 1 666px;
    padding: 40px 40px 75px 40px;

    @media (max-width: $md2+px) {
        flex: 0 1 588px;
    }

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 20px !important;
        padding: 40px 20px 25px 20px;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &:first-child,
    &:nth-child(2) {
        margin-bottom: 20px;
    }

    border-radius: 20px;
    background: #7A1212;
}

.whyRowColumnImg {
    margin-bottom: 20px;
}

.whyRowColumnTitle {
    margin-bottom: 20px;
    color: #fff !important;
}

.whyRowColumnText {
    color: rgba(255, 255, 255, 0.70) !important;
    font-weight: 300 !important;
}