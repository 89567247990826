@import "../../../styles/var";

.banner {
    padding: 193px 0 147px 0;
    position: relative;
    background-color: #7A1212;

    @media (max-width: $md2+px) {
        padding: 150px 0 120px 0;
    }

    @media (max-width: $md3+px) {
        padding: 120px 0 80px 0;
    }

    @media (max-width: $md4+px) {
        padding: 120px 0 0 0;
    }
}

.bannerContent {
    position: relative;
    z-index: 5;
}

.bannerBody {}

.bannerOverTitle {
    margin-bottom: 10px;
}

.bannerTitle {
    max-width: 820px;
    margin-bottom: 26px;
    font-weight: 500 !important;

    @media (max-width: $md2+px) {
        max-width: 600px;
    }

    @media (max-width: $md6+px) {
        max-width: 400px;
    }

    
}

.bannerText {
    max-width: 676px;
    margin-bottom: 66px;
    color: rgba($color: #fff, $alpha: 0.7) !important;

    @media (max-width: $md2+px) {
        max-width: 500px;
    }

    @media (max-width: $md6+px) {
        max-width: 320px;
    }
}

.bannerBtn {}

.image {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 805px;

    @media (max-width: 1600px) {
        width: 650px;
        right: 20px;
    }

    @media (max-width: $md2+px) {
        width: 550px;
    }

    @media (max-width: $md3+px) {
        width: 450px;
    }

    @media (max-width: $md4+px) {
        position: relative;
        right: unset;
        bottom: unset;
        display: flex;
        margin: 40px auto 0;
        width: 100%;
        max-width: 450px;
    }

    @media (max-width: $md5+px) {
        max-width: 260px;
    }
}