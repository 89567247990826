@import "./_var.scss";

body,
html {
    background: #ffffff;
    color: rgba(0, 0, 0, 0.7);

    &.lock {
        overflow: hidden;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pt {
    padding-top: 180px;

    @media (max-width: $md1+px) {
        padding-top: 160px;
    }

    @media (max-width: $md4+px) {
        padding-top: 140px;
    }

    @media (max-width: $md6+px) {
        padding-top: 120px;
    }
}

.mt {
    margin-top: 140px;

    @media (max-width: $md1+px) {
        margin-top: 140px;
    }

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.mb {
    margin-bottom: 140px;

    @media (max-width: $md1+px) {
        margin-bottom: 140px;
    }

    @media (max-width: $md4+px) {
        margin-bottom: 140px;
    }

    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.Dropdown-control {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 30px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: none;
    }
}

.Dropdown-menu {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.Dropdown-arrow {
    border-color: #fff transparent transparent;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
}

.font-36 {
    font-size: 36px;
    font-family: $lato;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;

    @media (max-width: $md2+px) {
        font-size: 32px;
    }

    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-64 {
    font-size: 64px;
    font-weight: 600;
    color: #fff;
    line-height: 1.2;
    font-family: $lato;

    @media (max-width: $md2+px) {
        font-size: 48px;
    }

    @media (max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-40 {
    font-size: 40px;
    font-family: $lato;
    font-weight: 600;
    color: #7A1212;
    line-height: 1.2;

    @media (max-width: $md2+px) {
        font-size: 36px;
    }

    @media (max-width: $md6+px) {
        font-size: 26px;
    }
}

.font-20 {
    font-size: 20px;
    font-family: $inter;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.5;

    @media (max-width: $md2+px) {
        font-size: 16px;
    }

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-24 {
    font-size: 24px;
    font-family: $inter;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.5;

    @media (max-width: $md2+px) {
        font-size: 20px;
    }
}

.font-16 {
    font-size: 16px;
    font-family: $inter;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-14 {
    font-size: 14px;
    line-height: 1.5;
    font-family: $inter;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
}

.font-12 {
    font-size: 12px;
    line-height: 1.5;
    font-family: $inter;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
}