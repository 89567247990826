@import "../../../styles/var";

.block {
}
.blockBody {
}
.blockRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.blockRowLeft {
    flex: 0 1 680px;
    @media (max-width: $md2+px) {
        flex: 0 1 680px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.blockRowLeftText {
    margin: 20px 0 40px;
    font-weight: 500!important;
    max-width: 680px;
    @media (max-width: $md2+px) {
        max-width: 550px;
    }
    @media (max-width: $md3+px) {
        max-width: 500px;
    }
}
.blockRowLeftForm {
}
.blockRowLeftFormInput {
    margin-bottom: 18px;
    border-radius: 40px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    max-width: 457px;
    height: 54px;
    line-height: 54px;
    &.error {
        border: 1px solid red;
    }
    @media (max-width: $md6+px) {
        height: 45px;
        line-height: 45px;
    }
    input {
        width: 100%;
        background-color: transparent;
        padding: 0 30px;
        @media (max-width: $md6+px) {
            padding: 0 24px;
        }
    }
}
.blockRowLeftFormCheckout {
    margin-top: 12px;
    display: flex;
    cursor: pointer;
    line-height: 1 !important;

    span {
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        width: 12px;
        display: block;
        height: 12px;
        margin-right: 8px;
    }
    &.error {
        span {
            border: 1px solid red;
        }
    }
    &.active {
        span {
            background-color: rgba(0, 0, 0, 0.20);
        }
    }
}
.blockRowLeftFormBtn {
    margin-top: 40px;
}
.blockRowRight {
    flex: 0 1 554px;
    @media (max-width: $md2+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md3+px) {
        margin-top: 60px;
    }
    img {
        width: 100%;
    }
}
