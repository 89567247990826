@import "../../../styles/var";

.block {
    background: #7A1212;
    padding: 75px 0;

    @media (max-width: $md6+px) {
        padding: 60px 0;
    }
}

.blockBody {}

.blockRow {}

.blockRowLeft {}

.title {
    text-align: center;
    color: #fff !important;
    font-weight: 500 !important;
}

.blockRowLeftText {
    text-align: center;
    font-weight: 500 !important;
    margin: 20px auto 40px;
    color: rgba(255, 255, 255, 0.70) !important;
    max-width: 634px;

    @media (max-width: $md3+px) {
        max-width: 500px;
    }
}

.blockRowLeftForm {
    max-width: 457px;
    width: 100%;
    margin: 0 auto;
}

.blockRowLeftFormInput {
    margin-bottom: 18px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    max-width: 100%;
    height: 54px;
    line-height: 54px;

    @media (max-width: $md6+px) {
        height: 45px;
        line-height: 45px;
    }

    &.error {
        border: 1px solid red;
    }

    input {
        width: 100%;
        background-color: transparent;
        padding: 0 30px;
        color: rgba(255, 255, 255, 0.70);

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        @media (max-width: $md6+px) {
            padding: 0 24px;
        }
    }
}

.blockRowLeftFormCheckout {
    margin-top: 12px;
    display: flex;
    cursor: pointer;
    line-height: 1 !important;

    color: rgba(255, 255, 255, 0.50)!important;

    span {
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        width: 12px;
        display: block;
        height: 12px;
        margin-right: 8px;
    }

    &.error {
        span {
            border: 1px solid red;
        }
    }

    &.active {
        span {
            background-color: rgba(255, 255, 255, 0.20);
        }
    }
}

.blockRowLeftFormBtn {
    text-align: center;
    margin-top: 40px;
}

.blockRowRight {
    flex: 0 1 571px;

    @media (max-width: $md3+px) {
        margin-top: 60px;
        flex: 0 1 100%;
    }

    img {
        width: 100%;
    }
}