@import "../../../styles/var";

.block {
}
.blockBody {
}
.blockRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.blockRowLeft {
    padding-top: 20px;
    flex: 0 1 394px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 60px;
    }
}
.blockRowLeftTitle {
    margin-bottom: 137px;
    @media (max-width: $md3+px) {
        max-width: 400px;
        margin-bottom: 0;
    }
}
.blockRowLeftBottom {
    display: flex;
}
.blockRowLeftBottomLeft {
    margin-right: 15px;
    cursor: pointer;
}
.blockRowLeftBottomRight {
    cursor: pointer;
    img {
        transform: rotate(180deg);
    }
}
.blockRowRight {
    flex: 0 1 930px;
    max-width: 930px;
    @media (max-width: $md2+px) {
        flex: 0 1 800px;
        max-width: 800px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        max-width: 100%;
        :global(.swiper) {
            overflow: visible !important;
        }
    }
    @media (max-width: $md4+px) {
        flex: 0 1 900px;
        max-width: 900px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 800px;
        max-width: 800px;
    }
    @media (max-width: $md6+px) {
        flex: 0 1 100%;
        max-width: 100%;
    }
}

.blockRowRightSlide {
    padding: 20px 20px 0 20px;
    @media (max-width: $md3+px) {
        padding: 0px 20px 0 20px;
    }
    @media (max-width: $md4+px) {
        padding: 0px 20px 0 20px;
    }
    @media (max-width: $md5+px) {
        padding: 0px 15px 0 15px;
    }
}
.blockRowRightSlideItem {
    text-align: center;
}
.blockRowRightSlideItemImg {
    margin-bottom: 40px;
    box-shadow: 0px 3px 20px 1px rgba(103, 88, 65, 0.1);
    border-radius: 25px;
    @media (max-width: $md6+px) {
        width: 70%;
        margin: 0 auto 30px auto;
    }
    img {
        width: 100%;
    }
}
.blockRowRightSlideItemTitle {
    margin-bottom: 12px;
}
.blockRowRightSlideItemSubTitle {
}
