@import "../../../styles/var";

.approach {
    position: relative;
}

.approachBody {}

.approachContent {
    max-width: 666px;

    @media (max-width: $md2+px) {
        max-width: 566px;
    }

    @media (max-width: $md4+px) {
        max-width: 100%;
    }
}

.approachContentTitle {
    margin-bottom: 40px;
}

.approachContentText {}

.approachContentList {
    margin-top: 40px;

    li {
        padding-left: 55px;
        margin-bottom: 25px;
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            left: 0;
            top: 0;
            position: absolute;
            width: 33.55px;
            height: 28.73px;
            background: url("../../../assets/img/main/4/2.png") 0 0 / contain no-repeat;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.approachContentListItem {}

.body {
    display: grid;
    grid-template-columns: 1fr 554px;
    justify-content: space-between;
    gap: 40px;
    align-items: flex-end;

    @media (max-width: $md3+px) {
        grid-template-columns: 1fr 450px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 50px;
    }
}

.image {
    width: 100%;
    max-width: 554px;

    @media (max-width: $md4+px) {
        display: flex;
        margin: 0 auto;
        max-width: 500px;
    }
}