@import "../../../styles/var";

.button {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;

    border-radius: 30px;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #000;
    transition: all 0.3s;

    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(103, 88, 65, 0.20);
    border-radius: 40px;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        background: rgba($color: #fff, $alpha: 0.7);
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.red {
        background: #7A1212;
        color: #fff;

        &:hover {
            background: rgba($color: #7A1212, $alpha: 0.7);
        }
    }
}
