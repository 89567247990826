@import "../../../styles/var";

.block {}

.body {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 554px;
    justify-content: space-between;
    gap: 120px;
    align-items: flex-end;

    @media (max-width: $md2+px) {
        grid-template-columns: 1fr 450px;
    }

    @media (max-width: $md3+px) {
        grid-template-columns: 1fr 400px;
        gap: 50px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }
}

.content {

    .title {
        color: #7A1212!important;
        max-width: 570px;

        @media (max-width: $md2+px) {
            max-width: 480px;
        }
    }

    .cards {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px 20px;

        @media (max-width: $md2+px) {
            margin-top: 50px;
            gap: 40px 20px;
        }

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin-top: 40px;
        }

        .card {
            position: relative;
            padding-left: 28px;

            @media (max-width: $md6+px) {
                padding-left: 24px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 10px;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #7A1212;

                @media (max-width: $md2+px) {
                    top: 8px;
                }

                @media (max-width: $md6+px) {
                    top: 7px;
                }
            }

            .subtitle {
                font-weight: 500 !important;
            }

            .text {
                color: rgba(0, 0, 0, 0.70);
                font-family: $inter;
                font-size: 14px;
                font-weight: 300;
                line-height: 150%;
                margin-top: 16px;
                max-width: 295px;

                @media (max-width: 600px) {
                    max-width: 100%;
                }

                @media (max-width: $md6+px) {
                    margin-top: 10px;
                }
            }
        }
    }
}

.image {
    width: 100%;
    max-width: 554px;

    @media (max-width: $md4+px) {
        display: flex;
        margin: 0 auto;
        max-width: 450px;
    }
}