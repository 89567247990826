@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #7A1212;
    transition: all 0.3s;

    &.hideBg {
        background-color: rgba(255, 255, 255, 0);
    }

    &.lightBg {
        .headerRowLinks {
            a {
                color: #fff;
            }

            .linkActive {
                color: #3279ff;
            }
        }

        :global(.Dropdown-control) {
            color: #fff !important;
        }

        :global(.Dropdown-arrow) {
            border-color: #fff transparent transparent !important;
        }

        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent #fff !important;
        }
    }

    @media (max-width: $md3+px) {
        height: 80px;
    }

    @media (max-width: $md6+px) {
        height: 70px;
    }

    &::before {
        content: "";
        // background: #004FE6;
        background: linear-gradient(180deg, #bfbfbf 0%, #f7f7f7 100%);
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            display: none;
        }

        path {
            fill: white;
        }
    }

    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }

        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        .aLink {
            display: block;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            font-family: $inter;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
        }

        .btn {
            justify-content: center;
            display: flex;
            margin-top: 40px;

            a {
                @media (min-width: 1221px) {
                    display: none;
                }

                display: block;
                padding: 7px 18px;
                border: 1px solid #745527;
                border-radius: 30px;
                font-family: $inter;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                cursor: pointer;
                transition: all 0.3s;
                color: #745527;
                background: transparent;

                &:hover {
                    color: #fff;
                    background: #745527;
                }
            }
        }

        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: flex;
        grid-template-columns: 55px 1fr auto;
        gap: 45px;
        align-items: center;
        padding: 12px 0;

        @media (max-width: $md2+px) {
            padding: 10px 0;
        }

        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
            gap: 30px;
        }

        @media (max-width: $md6+px) {
            padding: 10px 0;
        }

        &Logo {
            img {
                height: 70px;

                @media (max-width: $md3+px) {
                    height: 60px;
                }

                @media (max-width: $md6+px) {
                    height: 50px;
                }
            }

            svg {
                width: 100%;

                path {
                    transition: all 0.3s;
                }

                @media (max-width: $md3+px) {
                    width: 46px;
                }
            }
        }

        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }

            display: flex;
            gap: 45px;
            font-family: $inter;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;

            @media (max-width: $md1+px) {
                font-size: 18px;
            }

            @media (max-width: $md2+px) {
                font-size: 16px;
                line-height: 19px;
                gap: 40px;
            }

            .linkActive {
                color: #745527;
            }

            a {
                color: #000000;
                text-decoration: none;
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            @media (max-width: $md6+px) {
                gap: 30px;
            }

            .btnCons {
                font-family: $inter;
                font-size: 18px;
                font-weight: 400;
                color: #fff;

                &:hover {
                    color: rgba($color: #fff, $alpha: 0.7)
                }
            }

            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }

                padding: 7px 18px;
                background: transparent;
                border: 1px solid #745527;
                border-radius: 30px;
                font-family: $inter;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                color: #745527;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    color: #fff;
                    background: #745527;
                }
            }

            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }

                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #745527;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #745527;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                        // background: #FFF;
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                        // background: #FFF;
                    }
                }
            }
        }
    }
}