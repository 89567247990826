@import "../../../styles/var";

.thankpage {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    position: fixed;
    overflow: auto;
}

.thankpageBody {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
}

.thankpageContent {
    border-radius: 20px;
    background: #7A1212;
    padding: 90px 20px;
    max-width: 828px;
    margin: 120px auto 0 auto;
    text-align: center;
}

.thankpageContentTitle {
    margin-bottom: 20px;
}

.thankpageContentText {
    margin: 0 auto 40px auto;
    max-width: 383px;
    color: #fff !important;
    font-weight: 500 !important;
}

.thankpageContentBtn {}